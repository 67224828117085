
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.topWrapper {
    background: linear-gradient(180deg, #ffffff -13.15%, #d1d9ff 100%);
    @media screen and (max-width: 414px) {
        padding-bottom: 30px;
    }
}
