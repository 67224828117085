
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    height: auto;
    min-height: calc(100vh - 58px);
    padding-top: 40px;
    @media screen and (max-width: 650px) {
        padding-top: 20px;
    }

    .form {
        z-index: 5;
        width: 100%;
        padding: 0 !important;
        max-width: 700px;
        border-radius: 10px;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        display: flex;
        flex-direction: column;
        margin-left: auto !important;
        margin-right: auto !important;
        position: relative;
        min-width: inherit;
        @media screen and (min-width: 1140px) {
            min-width: 520px !important;
        }
        @media screen and (min-width: 768px) {
            min-width: 420px !important;
        }
        @media screen and (max-width: 500px) {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 22px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-size: 22px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
            // @include lg {
            //     margin-left: -50px;
            //     margin-right: -50px;
            // }
            @media screen and (max-width: 500px) {
                font-size: 20px !important;
                line-height: 1.4;
            }
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            background-color: $workerscompensation-default-button-bgcolor !important;
            transition: all 0.2s ease-in-out;
            @include lg {
                max-width: 450px !important;
            }
            &:hover {
                background-color: $workerscompensation-default-button-hover-bgcolor !important;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 450px;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;

            margin-top: 10px;

            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: $workerscompensation-radio-default-hover-button-bgcolor;
                        border-color: $workerscompensation-radio-default-button-border-color;
                        color: $workerscompensation-radio-default-button-text-color !important;
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid
                        $workerscompensation-default-checkbox-border-color;
                    color: $workerscompensation-radio-default-button-text-color;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: row;
                    text-align: center;
                    & > div {
                        margin-right: 20px;
                        & + span {
                            text-align: left;
                        }
                    }
                    &:hover {
                        background-color: $workerscompensation-radio-default-hover-button-bgcolor;
                        // color: white;
                    }
                    span {
                        font-size: 14px !important;
                        margin-top: 0 !important;
                        width: 100%;
                        @include md {
                            font-size: 16px !important;
                        }
                    }
                    @include md {
                        min-height: 50px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                }
                @include md {
                    margin: 0 10px 15px;
                    width: 60% !important;
                    margin-left: auto;
                    margin-right: auto;
                }
                &:last-child {
                    margin-bottom: 10px !important;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: $workerscompensation-default-checkbox-selected-bgcolor;
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid
                        $workerscompensation-default-checkbox-border-color;
                    color: #000;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    & > span:first-child {
                        border-radius: 3px;
                    }
                    @include md {
                        min-height: 50px;
                    }
                }
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
        @include lg {
            min-width: 400px;
            width: 60%;
            padding: 40px 40px;
            margin-bottom: 200px;
            margin-top: 50px;
        }
    }
    .image {
        z-index: 2;
        & > div {
            position: absolute !important;
            left: 0;
            bottom: 0;
            height: 300px;
            width: 100vw;
            max-width: unset !important;
            left: 50% !important;
            transform: translateX(-50%) !important;

            @include lg {
                height: 100%;
                top: 0 !important;
                left: 0%;
            }
        }

        img {
            min-height: unset !important;
            min-width: unset !important;
            width: auto !important;
            height: 100% !important;
            max-width: unset !important;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }
}
.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
    @media screen and (max-width: 1080px) {
        min-height: 400px;
    }
}
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
}

.wrapper {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.2);
    width: 1288px;
    margin: 0 auto;

    @media screen and (max-width: 1320px) {
        margin-left: 20px;
        margin-right: 20px;
    }
    @media screen and (max-width: 1080px) {
        padding-bottom: 40px;
    }
    & > div {
        width: 50%;
        @media screen and (max-width: 766px) {
            width: 100%;
            max-width: 360px;
        }
    }
}
.sideImage {
    border-radius: 0 30px 30px 0;
    overflow: hidden;
    @media screen and (max-width: 1080px) {
        display: none;
    }
}

.progress-background {
    & > div:first-child span {
        color: $workerscompensation-default-progress-buble-text-color;
    }
    & > div:last-child {
        background-color: $workerscompensation-default-progress-bgcolor;
    }
}

.back-button {
    bottom: -20px !important;
    left: 0;
    // @media screen and (max-width: 1023px) {
    //     left: 25px;
    // }
    @media screen and (max-width: 499px) {
        left: 20px;
    }
}
