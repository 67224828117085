$businessphoneprices-go-go-green: #008a7b;
$businessphoneprices-orange: #ffb212;
$businessphoneprices-green: #5bc1a4;
$workerscompensation-default-checkbox-selected-bgcolor: #e8f3f8;
$workerscompensation-default-checkbox-border-color: #6a4a9e;
$workerscompensation-radio-default-button-bgcolor: #fff;
$workerscompensation-radio-default-button-border-color: #6a4a9e;
$workerscompensation-radio-default-hover-button-bgcolor: #ccd4f9;
$workerscompensation-radio-default-button-text-color: #000;
$workerscompensation-default-button-bgcolor: #6a4a9e;
$workerscompensation-default-button-hover-bgcolor: #5a3b8b;
$workerscompensation-default-button-text-color: #ffffff;
$workerscompensation-form-border-color: #2e2e2e;

$workerscompensation-progress-main-color: #8d90e0;
$workerscompensation-default-progress-bgcolor: #dde3ff;
$workerscompensation-default-progress-buble-text-color: #fff;
$workerscompensation-why-us-title-color: #2e2e2e;
$workerscompensation-why-us-item-title-color: #000;
$workerscompensation-why-us-item-brief-color: #747474;
$workerscompensation-header-phone-color: #dd6453;
$workerscompensation-footer-color: #000;
:export {
    progressColor: $workerscompensation-progress-main-color;
    primaryColor: $workerscompensation-default-button-bgcolor;
    green: $businessphoneprices-green;
    whyUsTitle: $workerscompensation-why-us-title-color;
    whyUsItemTitle: $workerscompensation-why-us-item-title-color;
    whyUsItemBrief: $workerscompensation-why-us-item-brief-color;
    headerPhoneColor: $workerscompensation-header-phone-color;
}
